import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AddResourceDialog from "../../../components/groups/detail/AddResourceDialog";
import { RootState } from "../../../modules";
import { getCurrentUserIsAdmin } from "../../../modules/authentication/selector";
import {
  accountPartOfEmail,
  addResource,
  cancelAddResourceDialog,
  GroupInformation,
  ResourceType,
} from "../../../modules/groups";

interface ExportProps {
  id: string;
}

interface StateProps {
  information?: GroupInformation;
  isAdmin: boolean;
  showAddResourceDialog: boolean;
}

interface DispatchProps {
  addResource: (groupId: string, type: ResourceType, suffix: string, title: string, url: string) => void;
  cancelAddResourceDialog: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const AddResourceDialogContainer: React.FC<Props> = ({
  id,
  isAdmin,
  information,
  showAddResourceDialog,
  addResource,
  cancelAddResourceDialog,
}) => {
  const [type, setType] = useState(ResourceType.GitHubRepository);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  if (!information || id !== information.id || !isAdmin) {
    return null;
  }

  // Duplicate Check
  const groupIdPrefix = accountPartOfEmail(information.id) ?? "";
  const isDuplicate = (() => {
    const createName = groupIdPrefix + (name.length > 0 ? `_${name}` : "");
    switch (type) {
      case ResourceType.Drive:
        return information.sharedDrives.find((d) => d.id === createName) !== undefined;
      case ResourceType.GitHubRepository:
        return information.gitHubRepositories.find((d) => d.name === createName) !== undefined;
      case ResourceType.CloudSourceRepository:
        return information.sourceRepositories.find((d) => d.id === createName) !== undefined;
      case ResourceType.Link:
        return false;
    }
  })();

  return (
    <AddResourceDialog
      open={showAddResourceDialog}
      groupIdPrefix={groupIdPrefix}
      isDuplicate={isDuplicate}
      type={type}
      name={name}
      title={title}
      url={url}
      onChangeType={setType}
      onChangeName={(newName) => {
        if (!newName.match(/^[a-zA-Z0-9-]*$/)) {
          return;
        }
        setName(newName);
      }}
      onChangeTitle={setTitle}
      onChangeUrl={setUrl}
      onCancel={cancelAddResourceDialog}
      onAdd={() => addResource(information.id, type, name, title, url)}
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    information: state.groups.information,
    isAdmin: getCurrentUserIsAdmin(state),
    showAddResourceDialog: state.groups.showAddResourceDialog,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    addResource: (groupId, type, suffix, title, url) => dispatch(addResource({ groupId, type, suffix, title, url })),
    cancelAddResourceDialog: () => dispatch(cancelAddResourceDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddResourceDialogContainer);
