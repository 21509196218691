import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GitHubPanel from "../../components/dashboard/GitHubPanel";
import { RootState } from "../../modules";
import { requestGitHubAuthorize } from "../../modules/authentication";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  gitHubId: string | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {
  requestGitHubAuthorize: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GitHubPanelContainer: React.FC<Props> = ({ gitHubId, requestGitHubAuthorize }) => {
  return <GitHubPanel gitHubId={gitHubId} onAuthorize={requestGitHubAuthorize} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {
    gitHubId: state.authentication.userInformation?.gitHubId || null,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    requestGitHubAuthorize: () => dispatch(requestGitHubAuthorize()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GitHubPanelContainer);
