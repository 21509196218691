import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../modules";
import { AuthenticationStatus } from "../modules/authentication";
import Root from "../components/Root";
import RouterFrameContainer from "./RouterFrameContainer";
import MainAppBarContainer from "./parts/MainAppBarContainer";
import SnackbarsContainer from "./SnackbarsContainer";

interface ExportProps {
  children?: React.ReactNode;
}

interface StateProps {
  authenticationStatus: AuthenticationStatus;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const RootContainer: React.FC<Props> = ({ authenticationStatus, children }) => {
  const signedIn = authenticationStatus === AuthenticationStatus.Authenticated;
  return (
    <Root>
      {signedIn ? <MainAppBarContainer /> : undefined}
      <RouterFrameContainer>{children}</RouterFrameContainer>
      <SnackbarsContainer />
    </Root>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {
    authenticationStatus: state.authentication.status,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
