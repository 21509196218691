import * as React from "react";
import { Button, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import BlockIcon from "@material-ui/icons/Block";
import styles from "./NotPermittedPageStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  onSignOut: () => void;
}

const NotPermittedPage: React.FC<Props> = ({ onSignOut, classes, t }) => (
  <div className={classes.frame}>
    <div className={classes.container}>
      <BlockIcon />
      <Typography className={classes.message}>{t("Access not permitted for this account")}</Typography>
      <Button onClick={onSignOut}>{t("Sign out")}</Button>
    </div>
  </div>
);

export default withTranslation()(withStyles(styles)(NotPermittedPage));
