import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AuthenticationStatus } from "../modules/authentication";
import RouterFrame from "../components/RouterFrame";
import { RootState } from "../modules";
import SessionLoadingPageContainer from "./session/SessionLoadingPageContainer";
import NotPermittedPageContainer from "./session/NotPermittedPageContainer";
import LoginPageContainer from "./session/LoginPageContainer";

interface ExportProps {
  children?: React.ReactNode;
}

interface StateProps {
  authenticationStatus: AuthenticationStatus;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const RouterFrameContainer: React.FC<Props> = ({ authenticationStatus, children }) => {
  switch (authenticationStatus) {
    case AuthenticationStatus.Initial:
      return <SessionLoadingPageContainer />;
    case AuthenticationStatus.NotAuthenticated:
      return <LoginPageContainer />;
    case AuthenticationStatus.NotPermittedAccount:
      return <NotPermittedPageContainer />;
    case AuthenticationStatus.Authenticated:
      return <RouterFrame>{children}</RouterFrame>;
  }
};

function mapStateToProps(state: RootState): StateProps {
  return {
    authenticationStatus: state.authentication.status,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterFrameContainer);
