import * as firebase from "firebase/app";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { GroupMetadata } from "../groups";

// models
// ----------------------------------------

export type User = firebase.default.User;

export enum AuthenticationStatus {
  Initial = "initial",
  NotAuthenticated = "notAuthenticated",
  NotPermittedAccount = "notPermitted",
  Authenticated = "authenticated",
}

export enum UserType {
  NotPermitted = "notPermitted",
  User = "user",
  Admin = "admin",
}

export interface UserSessionInformation {
  type: UserType;
  groups: GroupMetadata[];
  gitHubId: string | null;
}

// State
// ----------------------------------------

export interface AuthenticationState {
  status: AuthenticationStatus;
  user?: User;
  userInformation?: UserSessionInformation;
}

const initialState: AuthenticationState = {
  status: AuthenticationStatus.Initial,
};

// Action Parameters
// ----------------------------------------

export interface ChangeAuthenticatedUserActionParameters {
  user?: User;
}

// interface ChangeAuthenticatedStatusActionParameters {
//   status: AuthenticationStatus;
// }

interface SetUserInformationActionParameters {
  userInformation?: UserSessionInformation;
}

export interface RegisterGitHubAccessTokenActionParameters {
  code: string;
  state: string;
}

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeProjects/Sessions");

export const changeAuthenticatedUser = actionCreator<ChangeAuthenticatedUserActionParameters>(
  "ChangeAuthenticatedUser"
);
// export const changeAuthenticatedStatus = actionCreator<ChangeAuthenticatedStatusActionParameters>(
//   "ChangeAuthenticatedStatus"
// );
export const setUserInformation = actionCreator<SetUserInformationActionParameters>("SetUserInformation");
export const requestSignIn = actionCreator<void>("RequestSignIn");
export const requestSignOut = actionCreator<void>("RequestSignOut");
export const requestGitHubAuthorize = actionCreator<void>("RequestGitHubAuthorize");
export const registerGitHubAccessToken = actionCreator<RegisterGitHubAccessTokenActionParameters>(
  "RegisterGitHubAccessToken"
);

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(changeAuthenticatedUser, (state, { user }) => ({
    ...state,
    user,
    status: user === undefined ? AuthenticationStatus.NotAuthenticated : state.status,
  }))
  .case(setUserInformation, (state, { userInformation }) => ({
    ...state,
    userInformation,
    status: (() => {
      if (!userInformation) {
        return AuthenticationStatus.NotAuthenticated;
      }
      switch (userInformation.type) {
        case UserType.NotPermitted:
          return AuthenticationStatus.NotPermittedAccount;
        case UserType.User:
        case UserType.Admin:
          return AuthenticationStatus.Authenticated;
      }
    })(),
  }));

export default reducer;
