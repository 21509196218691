import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./CreateGroupDialogStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  id: string;
  name: string;
  isErrorId: boolean;
  isErrorName: boolean;
  canSend: boolean;
  onBlurId: () => void;
  onBlurName: () => void;
  onChangeId: (id: string) => void;
  onChangeName: (name: string) => void;
  onCancel: () => void;
  onSend: () => void;
}

const CreateGroupDialog: React.FC<Props> = ({
  open,
  id,
  name,
  isErrorId,
  isErrorName,
  canSend,
  onBlurId,
  onBlurName,
  onChangeId,
  onChangeName,
  onCancel,
  onSend,
  t,
}) => (
  <Dialog open={open}>
    <DialogTitle>{t("Create a Project")}</DialogTitle>
    <DialogContent>
      <TextField
        error={isErrorId}
        autoFocus
        margin="dense"
        label={t("Project ID")}
        helperText={t("Project ID Form Hint Text")}
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={id}
        onBlur={onBlurId}
        onChange={(e) => onChangeId(e.target.value)}
      />
      <TextField
        error={isErrorName}
        margin="dense"
        label={t("Project Name")}
        helperText={t("Project Name Form Hint Text")}
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={name}
        onBlur={onBlurName}
        onChange={(e) => onChangeName(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>{t("Cancel")}</Button>
      <Button color="primary" disabled={!canSend} onClick={onSend}>
        {t("Create")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(withStyles(styles)(CreateGroupDialog));
