import * as React from "react";
import { AppBar, Toolbar, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./MainAppBarStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  accountsPanel?: React.ReactNode;
}

const MainAppBar: React.FC<Props> = ({ accountsPanel, classes, t }) => (
  <AppBar className={classes.appBar}>
    <Toolbar>
      <Typography className={classes.title} align="left" component="h1" variant="h6" color="inherit" noWrap>
        {t("PRODUCTION C-time! Project Management System")}
      </Typography>
      {accountsPanel}
    </Toolbar>
  </AppBar>
);

export default withTranslation()(withStyles(styles)(MainAppBar));
