import { RouterState } from "connected-react-router";
import { AuthenticationState } from "./authentication";
import { GroupsState } from "./groups";

export interface RootState {
  router: RouterState;
  authentication: AuthenticationState;
  groups: GroupsState;
}

export enum LoadingState {
  Initial = "initial",
  Loading = "loading",
  Failed = "failed",
}
