import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import DashboardPage from "../../components/dashboard/DashboardPage";
import { RootState } from "../../modules";
import ProjectsPanelContainer from "./ProjectsPanelContainer";
import GitHubPanelContainer from "./GitHubPanelContainer";
import CreateGroupDialogContainer from "../groups/list/CreateGroupDialogContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const DashboardPageContainer: React.FC<Props> = (props) => {
  return (
    <DashboardPage {...props}>
      <ProjectsPanelContainer />
      <GitHubPanelContainer />
      <CreateGroupDialogContainer />
    </DashboardPage>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer);
