import { call, put, takeEvery } from "redux-saga/effects";
import {
  changeAuthenticatedUser,
  ChangeAuthenticatedUserActionParameters,
  registerGitHubAccessToken,
  RegisterGitHubAccessTokenActionParameters,
  requestGitHubAuthorize,
  requestSignIn,
  requestSignOut,
  setUserInformation,
  UserSessionInformation,
} from "./index";
import { ProjectsFunctionsInstance, startSignInAsync, startSignOutAsync } from "../firebase";
import { Action } from "typescript-fsa";
import { replace } from "connected-react-router";

function* changeAuthenticatedUserSaga(action: Action<ChangeAuthenticatedUserActionParameters>) {
  if (!action.payload.user?.email) {
    return;
  }
  const userInformation: UserSessionInformation = yield call(async function () {
    return await ProjectsFunctionsInstance.getUserSessionInformationAsync();
  });
  yield put(setUserInformation({ userInformation }));
}

function* requestSignInSaga() {
  yield call(startSignInAsync);
}

function* requestSignOutSaga() {
  yield put(setUserInformation({ userInformation: undefined }));
  yield call(startSignOutAsync);
}

function* requestGitHubAuthorizeSaga() {
  location.href = yield call(async function () {
    const result = await ProjectsFunctionsInstance.getGitHubAuthUrlAsync();
    return result.url;
  });
}

function* registerGitHubAccessTokenSaga(action: Action<RegisterGitHubAccessTokenActionParameters>) {
  yield call(async function () {
    await ProjectsFunctionsInstance.registerGitHubAccessTokenAsync(action.payload.code, action.payload.state);
  });
  const userInformation: UserSessionInformation = yield call(async function () {
    return await ProjectsFunctionsInstance.getUserSessionInformationAsync();
  });
  yield put(setUserInformation({ userInformation }));
  yield put(replace("/"));
}

export default function* watchAuthentication() {
  yield takeEvery(changeAuthenticatedUser.type, changeAuthenticatedUserSaga);
  yield takeEvery(requestSignIn.type, requestSignInSaga);
  yield takeEvery(requestSignOut.type, requestSignOutSaga);
  yield takeEvery(requestGitHubAuthorize.type, requestGitHubAuthorizeSaga);
  yield takeEvery(registerGitHubAccessToken.type, registerGitHubAccessTokenSaga);
}
