import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    root: {
      background: theme.palette.background.default,
      display: "flex",
      flexGrow: 1,
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    subTitleText: {
      ...theme.typography.subtitle1,
      display: "block",
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },
    signInButton: {
      marginTop: theme.spacing(2),
    },
    footnoteText: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(4),
    },
  });
}
