import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./NotFoundPageStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithStyles<typeof styles>, WithTranslation {}

const NotFoundPage: React.FC<Props> = ({ classes }) => <div className={classes.root}>NotFoundPage</div>;

export default withTranslation()(withStyles(styles)(NotFoundPage));
