import * as React from "react";
import { Button, List, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GroupResourcesPanelStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  isAdmin: boolean;
  children?: React.ReactNode;
  onAddClick: () => void;
}

const GroupResourcesPanel: React.FC<Props> = ({ isAdmin, children, onAddClick, classes, t }) => (
  <div className={classes.root}>
    <Paper className={classes.frame}>
      <div className={classes.titleArea}>
        <Typography variant="subtitle1" className={classes.title}>
          {t("Project Resources")}
        </Typography>
        {isAdmin ? (
          <Button color="primary" onClick={onAddClick}>
            {t("Add Resource")}
          </Button>
        ) : null}
      </div>
      <List>{children}</List>
    </Paper>
  </div>
);

export default withTranslation()(withStyles(styles)(GroupResourcesPanel));
