import * as React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GroupPage from "../../../components/groups/detail/GroupPage";
import { LoadingState, RootState } from "../../../modules";
import { RouteComponentProps } from "react-router-dom";
import GroupBasicInformationPanelContainer from "./GroupBasicInformationPanelContainer";
import GroupResourcesPanelContainer from "./GroupResourcesPanelContainer";
import { getGroup } from "../../../modules/groups";
import LoadingViewContainer from "../../parts/LoadingViewContainer";
import AddResourceDialogContainer from "./AddResourceDialogContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  loadingState: LoadingState;
}

interface DispatchProps {
  getGroup: (groupId: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps & RouteComponentProps<{ groupId: string }>;

const GroupPageContainer: React.FC<Props> = ({ loadingState, match, getGroup }) => {
  const groupId = decodeURIComponent(match.params.groupId);
  useEffect(() => {
    getGroup(groupId);
  }, [match.params.groupId]);
  return (
    <GroupPage>
      {loadingState === LoadingState.Initial ? (
        <>
          <GroupBasicInformationPanelContainer id={groupId} />
          <GroupResourcesPanelContainer id={groupId} />
          <AddResourceDialogContainer id={groupId} />
        </>
      ) : null}
      {loadingState === LoadingState.Loading ? <LoadingViewContainer /> : null}
    </GroupPage>
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    loadingState: state.groups.informationLoadingState,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getGroup: (groupId: string) => dispatch(getGroup({ groupId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupPageContainer);
