import React from "react";
import { Suspense } from "react";
import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider, Theme } from "@material-ui/core";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import Router from "./containers/Router";
import RootContainer from "./containers/RootContainer";
import createStore, { history } from "./configureStore";
import { setupAuthentication, setupFirebase } from "./modules/firebase";
import "./modules/i18n";

const theme = ((): Theme => {
  return createMuiTheme({});
})();

// IE11の時だけLoading表示する
const fallback = window.navigator.userAgent.indexOf("Trident") !== -1 ? "Loading..." : "";

declare let firebaseConfig: Record<string, string>;
const store = createStore();
setupFirebase(firebaseConfig);
setupAuthentication(store);

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <Suspense fallback={fallback}>
            <RootContainer>
              <Router />
            </RootContainer>
          </Suspense>
        </ConnectedRouter>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
