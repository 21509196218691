import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./DashboardPageStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
}

const DashboardPage: React.FC<Props> = ({ children, classes }) => <div className={classes.root}>{children}</div>;

export default withTranslation()(withStyles(styles)(DashboardPage));
