import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    frame: {
      display: "flex",
      flexGrow: 1,
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      textAlign: "center",
    },
    message: {
      ...theme.typography.subtitle1,
      display: "block",
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(2),
    },
  });
}
