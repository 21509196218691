import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GitHubOAuthCallbackPage from "../../components/github/GitHubOAuthCallbackPage";
import { RootState } from "../../modules";
import { useEffect } from "react";
import { registerGitHubAccessToken } from "../../modules/authentication";
import { replace } from "connected-react-router";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  code: string | undefined;
  state: string | undefined;
}

interface DispatchProps {
  registerGitHubAccessToken: (code: string, state: string) => void;
  replaceToDashboard: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GitHubOAuthCallbackPageContainer: React.FC<Props> = ({
  code,
  state,
  registerGitHubAccessToken,
  replaceToDashboard,
}) => {
  useEffect(() => {
    if (code && state) {
      registerGitHubAccessToken(code, state);
    } else {
      replaceToDashboard();
    }
  }, []);
  return <GitHubOAuthCallbackPage />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {
    code: state.router.location.query.code,
    state: state.router.location.query.state,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    registerGitHubAccessToken: (code: string, state: string) => dispatch(registerGitHubAccessToken({ code, state })),
    replaceToDashboard: () => dispatch(replace("/")),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GitHubOAuthCallbackPageContainer);
