import { call, put, takeEvery } from "redux-saga/effects";
import {
  addResource,
  AddResourceActionParameters,
  addResourceLoading,
  createGroup,
  CreateGroupActionParameters,
  createGroupLoading,
  getGroup,
  GetGroupActionParameters,
  getGroupLoading,
  GroupInformation,
  removeLink,
  RemoveLinkActionParameters,
  removeLinkLoading,
  requestSynchronizeGitHubUsers,
  requestSynchronizeGitHubUsersLoading,
  RequestSynchronizeGitHubUsersParameters,
  ResourceType,
} from "./index";
import { Action } from "typescript-fsa";
import { ProjectsFunctionsInstance } from "../firebase";
import { setUserInformation, UserSessionInformation } from "../authentication";

function* getGroupSaga(action: Action<GetGroupActionParameters>) {
  const loadingAction = getGroupLoading;
  const params = action.payload;
  try {
    yield put(loadingAction.started(params));

    const result: GroupInformation | undefined = yield call(
      async () => await ProjectsFunctionsInstance.getGroupInformationAsync(params.groupId)
    );
    if (!result) {
      yield put(loadingAction.failed({ params, error: new Error("Error Response") }));
      return;
    }

    yield put(loadingAction.done({ params, result: { information: result } }));
  } catch (e) {
    yield put(loadingAction.failed({ params, error: e }));
  }
}

function* createGroupSaga(action: Action<CreateGroupActionParameters>) {
  const loadingAction = createGroupLoading;
  const params = action.payload;
  try {
    yield put(loadingAction.started(params));

    const result: unknown | null = yield call(
      async () => await ProjectsFunctionsInstance.createGroupAsync(params.groupId, params.groupName)
    );
    if (!result) {
      yield put(loadingAction.failed({ params, error: new Error("Failed") }));
    }

    const userInformation: UserSessionInformation = yield call(async function () {
      return await ProjectsFunctionsInstance.getUserSessionInformationAsync();
    });
    yield put(setUserInformation({ userInformation }));

    yield put(loadingAction.done({ params }));
  } catch (e) {
    yield put(loadingAction.failed({ params, error: e }));
  }
}

function* requestSynchronizeGitHubUsersSaga(action: Action<RequestSynchronizeGitHubUsersParameters>) {
  const loadingAction = requestSynchronizeGitHubUsersLoading;
  const params = action.payload;
  try {
    yield put(loadingAction.started(params));

    yield call(async () => await ProjectsFunctionsInstance.synchronizeGitHubUsersAsync(params.groupId));

    yield put(loadingAction.done({ params }));
  } catch (e) {
    yield put(loadingAction.failed({ params, error: e }));
  }
}

function* addResourceSaga(action: Action<AddResourceActionParameters>) {
  const loadingAction = addResourceLoading;
  const params = action.payload;
  try {
    yield put(loadingAction.started(params));

    switch (params.type) {
      case ResourceType.GitHubRepository:
        yield call(async () => await ProjectsFunctionsInstance.addGitHubRepositoryAsync(params.groupId, params.suffix));
        break;
      case ResourceType.Link:
        yield call(async () => await ProjectsFunctionsInstance.addLinkAsync(params.groupId, params.title, params.url));
        break;
      default:
        yield put(loadingAction.failed({ params, error: new Error("Not implemented") }));
        return;
    }

    yield put(loadingAction.done({ params }));
  } catch (e) {
    yield put(loadingAction.failed({ params, error: e }));
  } finally {
    yield put(getGroup({ groupId: params.groupId }));
  }
}

function* removeLinkSaga(action: Action<RemoveLinkActionParameters>) {
  const loadingAction = removeLinkLoading;
  const params = action.payload;
  try {
    yield put(loadingAction.started(params));

    yield call(async () => await ProjectsFunctionsInstance.removeLinkAsync(params.groupId, params.id));

    yield put(loadingAction.done({ params }));
  } catch (e) {
    yield put(loadingAction.failed({ params, error: e }));
  } finally {
    yield put(getGroup({ groupId: params.groupId }));
  }
}

export default function* watchGroups() {
  yield takeEvery(getGroup.type, getGroupSaga);
  yield takeEvery(createGroup.type, createGroupSaga);
  yield takeEvery(requestSynchronizeGitHubUsers.type, requestSynchronizeGitHubUsersSaga);
  yield takeEvery(addResource.type, addResourceSaga);
  yield takeEvery(removeLink.type, removeLinkSaga);
}
