import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ProjectsPanel from "../../components/dashboard/ProjectsPanel";
import { RootState } from "../../modules";
import { GroupMetadata, showCreateGroupDialog } from "../../modules/groups";
import ProjectsListItemContainer from "./ProjectsListItemContainer";
import { getCurrentUserIsAdmin } from "../../modules/authentication/selector";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  groups: GroupMetadata[];
  isAdmin: boolean;
}

interface DispatchProps {
  showCreateGroupDialog: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const ProjectsPanelContainer: React.FC<Props> = ({ groups, isAdmin, showCreateGroupDialog }) => {
  return (
    <ProjectsPanel isAdmin={isAdmin} onAddProject={showCreateGroupDialog}>
      {groups.map((g) => (
        <ProjectsListItemContainer key={g.id} group={g} />
      ))}
    </ProjectsPanel>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {
    groups: state.authentication.userInformation?.groups || [],
    isAdmin: getCurrentUserIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    showCreateGroupDialog: () => dispatch(showCreateGroupDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPanelContainer);
