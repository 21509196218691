import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    root: {},
    caption: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    userName: {
      marginRight: theme.spacing(1),
    },
    accountIcon: {
      verticalAlign: "bottom",
    },
    accountImage: {
      width: "32px",
      height: "32px",
      borderRadius: "16px",
      verticalAlign: "bottom",
    },
  });
}
