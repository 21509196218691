import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GroupBasicInformationPanel from "../../../components/groups/detail/GroupBasicInformationPanel";
import { RootState } from "../../../modules";
import { GroupInformation, requestSynchronizeGitHubUsers } from "../../../modules/groups";
import { replace } from "connected-react-router";
import { getCurrentUserIsAdmin } from "../../../modules/authentication/selector";
import { useState } from "react";

interface ExportProps {
  id: string;
}

interface StateProps {
  information?: GroupInformation;
  isAdmin: boolean;
}

interface DispatchProps {
  backToRoot: () => void;
  requestSynchronizeGitHubUsers: (groupId: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GroupBasicInformationPanelContainer: React.FC<Props> = ({
  id,
  information,
  isAdmin,
  backToRoot,
  requestSynchronizeGitHubUsers,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  if (!information || id !== information.id) {
    return null;
  }
  return (
    <GroupBasicInformationPanel
      groupId={information.id}
      groupName={information.name}
      groupUrl={information.groupUrl}
      isAdmin={isAdmin}
      menuOpen={menuOpen}
      onBack={backToRoot}
      onMenuOpen={() => setMenuOpen(true)}
      onMenuClose={() => setMenuOpen(false)}
      onSynchronizeGitHub={() => {
        requestSynchronizeGitHubUsers(information.id);
        setMenuOpen(false);
      }}
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    information: state.groups.information,
    isAdmin: getCurrentUserIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    backToRoot: () => dispatch(replace("/")),
    requestSynchronizeGitHubUsers: (groupId: string) => dispatch(requestSynchronizeGitHubUsers({ groupId })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupBasicInformationPanelContainer);
