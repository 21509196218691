import * as React from "react";
import { IconButton, Snackbar, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GitHubSynchronizeRequestedSnackbarStyles";
import CloseIcon from "@material-ui/icons/Close";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  onClose: () => void;
}

const GitHubSynchronizeRequestedSnackbar: React.FC<Props> = ({ open, onClose, t }) => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={open}
    autoHideDuration={5000}
    message={t("Requested synchronization")}
    action={
      <IconButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    }
    onClose={onClose}
  />
);

export default withTranslation()(withStyles(styles)(GitHubSynchronizeRequestedSnackbar));
