import { applyMiddleware, combineReducers, createStore as reduxCreateStore } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import { all } from "redux-saga/effects";

import authenticationReducer from "./modules/authentication";
import authenticationSaga from "./modules/authentication/saga";
import groupsReducer from "./modules/groups";
import groupsSaga from "./modules/groups/saga";

const sagaMiddleware = createSagaMiddleware();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootSaga = function* (): any {
  yield all([authenticationSaga(), groupsSaga()]);
};

export const history = createBrowserHistory();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createStore(): any {
  const reducer = combineReducers({
    router: connectRouter(history),
    authentication: authenticationReducer,
    groups: groupsReducer,
  });
  const middleware = applyMiddleware(sagaMiddleware, routerMiddleware(history), logger);
  const store = reduxCreateStore(reducer, middleware);
  sagaMiddleware.run(rootSaga);

  return store;
}
