import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import { changeAuthenticatedUser } from "../authentication";
import { Store } from "redux";
import { ProjectsFunctions } from "./functions";

let firebaseApp: firebase.app.App;
export let ProjectsFunctionsInstance: ProjectsFunctions;
// let reduxSagaFirebase: ReduxSagaFirebase;
const provider = new firebase.auth.GoogleAuthProvider();

export function setupFirebase(config: Record<string, string>): void {
  if (firebaseApp) {
    return;
  }
  console.log(firebaseApp);
  firebaseApp = firebase.initializeApp(config);
  const functions = firebaseApp.functions("asia-northeast1");
  if (process.env.REACT_APP_FUNCTIONS_EMULATOR) {
    functions.useEmulator("127.0.0.1", 5001);
  }
  ProjectsFunctionsInstance = new ProjectsFunctions(functions);
  // reduxSagaFirebase = new ReduxSagaFirebase(firebaseApp);
}

export function setupAuthentication(store: Store): void {
  firebase.auth().onAuthStateChanged((user) => {
    store.dispatch(changeAuthenticatedUser({ user: user || undefined }));
  });
}
export function startSignInAsync(): Promise<void> {
  return firebase.auth().signInWithRedirect(provider);
}

export function startSignOutAsync(): Promise<void> {
  return firebase.auth().signOut();
}
