import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    appBar: {
      position: "absolute",
      zIndex: theme.zIndex.drawer + 1,
      // App bar - closing
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        // duration: 2000
      }),
    },
    menuButton: {},
    title: {
      flexGrow: 1,
    },
  });
}
