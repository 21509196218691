import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    root: {
      display: "flex",
    },
  });
}
