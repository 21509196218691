import * as React from "react";
import { Button, List, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./ProjectsPanelStyles";
import PageRoot from "../parts/PageRoot";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
  isAdmin?: boolean;
  onAddProject: () => void;
}

const ProjectsPanel: React.FC<Props> = ({ isAdmin, children, onAddProject, classes, t }) => (
  <PageRoot>
    <div className={classes.root}>
      <Paper className={classes.frame}>
        <div className={classes.titleArea}>
          <Typography className={classes.title} variant="subtitle1">
            {t("Accessible Projects")}
          </Typography>
          {isAdmin ? (
            <Button variant="contained" onClick={onAddProject}>
              {t("Add a Project")}
            </Button>
          ) : undefined}
        </div>
        <List>{children}</List>
      </Paper>
    </div>
  </PageRoot>
);

export default withTranslation()(withStyles(styles)(ProjectsPanel));
