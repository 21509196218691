import * as React from "react";
import { IconButton, Snackbar, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./CreateGroupSnackbarStyles";
import { LoadingState } from "../../../modules";
import CloseIcon from "@material-ui/icons/Close";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  loadingState: LoadingState;
  onClose: () => void;
}

const CreateGroupSnackbar: React.FC<Props> = ({ loadingState, onClose, t }) => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={loadingState !== LoadingState.Initial}
    autoHideDuration={loadingState === LoadingState.Loading ? null : 5001}
    message={loadingState === LoadingState.Loading ? t("Creating group") : t("Group creation Failed")}
    action={
      loadingState === LoadingState.Failed ? (
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null
    }
    onClose={onClose}
  />
);

export default withTranslation()(withStyles(styles)(CreateGroupSnackbar));
