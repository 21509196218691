import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    root: {},
    dialogForm: {
      display: "flex",
      flexDirection: "column",
    },
    resourceListIcon: {
      marginRight: theme.spacing(1),
    },
    formControl: {
      marginBottom: theme.spacing(2),
      minWidth: 120,
    },
  });
}
