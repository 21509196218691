import * as React from "react";
import { CircularProgress, WithStyles, withStyles } from "@material-ui/core";
import styles from "./SessionLoadingPageStyles";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SessionLoadingPageProps extends WithStyles<typeof styles> {}

const SessionLoadingPage: React.FC<SessionLoadingPageProps> = ({ classes }) => (
  <div className={classes.root}>
    <CircularProgress />
  </div>
);

export default withStyles(styles)(SessionLoadingPage);
