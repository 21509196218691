import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GroupResourcesPanel from "../../../components/groups/detail/GroupResourcesPanel";
import { RootState } from "../../../modules";
import { GroupInformation, showAddResourceDialog } from "../../../modules/groups";
import GroupResourceListItemContainer from "./GroupResourceListItemContainer";
import { getCurrentUserIsAdmin } from "../../../modules/authentication/selector";

interface ExportProps {
  id: string;
}

interface StateProps {
  information?: GroupInformation;
  isAdmin: boolean;
}

interface DispatchProps {
  showAddResourceDialog: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GroupResourcesPanelContainer: React.FC<Props> = ({ id, information, isAdmin, showAddResourceDialog }) => {
  if (!information || id !== information.id) {
    return null;
  }
  return (
    <GroupResourcesPanel isAdmin={isAdmin} onAddClick={showAddResourceDialog}>
      {information.sharedDrives.map((v) => (
        <GroupResourceListItemContainer key={`drive-${v.id}`} sharedDrive={v} />
      ))}
      {information.gitHubRepositories.map((v) => (
        <GroupResourceListItemContainer key={`github-${v.fullName}`} gitHubRepository={v} />
      ))}
      {information.sourceRepositories.map((v) => (
        <GroupResourceListItemContainer key={`sourceRepository-${v.id}`} sourceRepository={v} />
      ))}
      {information.links.map((v) => (
        <GroupResourceListItemContainer key={`link-${v.id}`} link={v} />
      ))}
    </GroupResourcesPanel>
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    information: state.groups.information,
    isAdmin: getCurrentUserIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    showAddResourceDialog: () => dispatch(showAddResourceDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupResourcesPanelContainer);
