import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CreateGroupDialog from "../../../components/groups/list/CreateGroupDialog";
import { RootState } from "../../../modules";
import { useEffect, useState } from "react";
import { cancelCreateGroupDialog, createGroup } from "../../../modules/groups";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  open: boolean;
}

interface DispatchProps {
  createGroup: (groupId: string, groupName: string) => void;
  cancelCreateGroupDialog: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const CreateGroupDialogContainer: React.FC<Props> = ({ open, cancelCreateGroupDialog, createGroup }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [isErrorId, setIsErrorId] = useState(false);
  const [isErrorName, setIsErrorName] = useState(false);
  useEffect(() => {
    setId("");
    setName("");
    setIsErrorId(false);
    setIsErrorName(false);
  }, [open]);

  const validateId = (id: string) => !!id.match(/^[a-zA-Z0-9-]+$/);
  const validateName = (name: string) => name.length > 0;

  return (
    <CreateGroupDialog
      open={open}
      id={id}
      name={name}
      isErrorId={isErrorId}
      isErrorName={isErrorName}
      canSend={validateId(id) && validateName(name)}
      onBlurId={() => {
        setIsErrorId(!validateId(id));
      }}
      onBlurName={() => {
        setIsErrorName(!validateName(id));
      }}
      onChangeId={setId}
      onChangeName={setName}
      onCancel={cancelCreateGroupDialog}
      onSend={() => createGroup(id, name)}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {
    open: state.groups.showCreateDialog,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    createGroup: (groupId, groupName) => dispatch(createGroup({ groupId, groupName })),
    cancelCreateGroupDialog: () => dispatch(cancelCreateGroupDialog()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupDialogContainer);
