import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AccountsPanel from "../../components/parts/AccountsPanel";
import { RootState } from "../../modules";
import { requestSignOut, User } from "../../modules/authentication";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  user?: User;
}

interface DispatchProps {
  requestSignOut: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const AccountsPanelContainer: React.FC<Props> = ({ user, requestSignOut }) => {
  return <AccountsPanel user={user} onLogOut={requestSignOut} />;
};

function mapStateToProps(state: RootState): StateProps {
  return {
    user: state.authentication.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    requestSignOut: () => dispatch(requestSignOut()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPanelContainer);
