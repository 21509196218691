import * as React from "react";
import { ButtonBase, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import signInButton from "../../resources/signin.png";
import signInButton2x from "../../resources/signin@2x.png";
import styles from "./LoginPageStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  onSignIn: () => void;
}

const LoginPage: React.FC<Props> = ({ onSignIn, classes, t }) => (
  <div className={classes.root}>
    <Typography className={classes.subTitleText}>{t("PRODUCTION C-time Project Management System")}</Typography>
    <ButtonBase className={classes.signInButton} onClick={onSignIn}>
      <img src={signInButton} srcSet={`${signInButton} 1x, ${signInButton2x} 2x`} alt={t("Sign in with Google")} />
    </ButtonBase>
    <Typography className={classes.footnoteText}>{t("Can sign in with approved Google account only")}</Typography>
  </div>
);

export default withTranslation()(withStyles(styles)(LoginPage));
