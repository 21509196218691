import firebase from "firebase/app";
import { UserSessionInformation } from "../authentication";
import { GroupInformation } from "../groups";

export class ProjectsFunctions {
  private functions: firebase.functions.Functions;
  private readonly projectsFunction: firebase.functions.HttpsCallable;

  constructor(functions: firebase.functions.Functions) {
    this.functions = functions;
    this.projectsFunction = this.functions.httpsCallable("projectsCallableFunctions");
  }

  public async getUserSessionInformationAsync(): Promise<UserSessionInformation> {
    const result = await this.projectsFunction({ function: "getUserSessionInformation" });
    return result.data as UserSessionInformation;
  }

  public async getGitHubAuthUrlAsync(): Promise<{ url: string }> {
    const result = await this.projectsFunction({ function: "getGitHubAuthUrl" });
    return result.data as { url: string };
  }

  public async registerGitHubAccessTokenAsync(code: string, state: string): Promise<void> {
    await this.projectsFunction({ function: "registerGitHubAccessToken", data: { code, state } });
  }

  public async getGroupInformationAsync(group: string): Promise<GroupInformation | undefined> {
    const result = await this.projectsFunction({ function: "getGroupInformation", data: { group } });
    return result.data as GroupInformation | undefined;
  }

  public async createGroupAsync(groupId: string, groupName: string): Promise<GroupInformation | undefined> {
    const result = await this.projectsFunction({ function: "createGroup", data: { groupId, groupName } });
    return result.data as GroupInformation | undefined;
  }

  public async synchronizeGitHubUsersAsync(group: string): Promise<void> {
    await this.projectsFunction({ function: "synchronizeGitHubUsers", data: { group } });
  }

  public async addGitHubRepositoryAsync(group: string, suffix: string): Promise<void> {
    await this.projectsFunction({ function: "addGitHubRepository", data: { group, suffix } });
  }

  public async addLinkAsync(group: string, title: string, url: string): Promise<void> {
    await this.projectsFunction({ function: "addLink", data: { group, title, url } });
  }

  public async removeLinkAsync(group: string, id: string): Promise<void> {
    await this.projectsFunction({ function: "removeLink", data: { group, id } });
  }
}
