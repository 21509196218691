import * as React from "react";
import { List, ListItem, ListItemText, Popover, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./AccountsPanelStyles";
import { useState } from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { User } from "../../modules/authentication";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  user?: User;
  onLogOut: () => void;
}

const AccountsPanel: React.FC<Props> = ({ user, onLogOut, classes, t }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  return (
    <div className={classes.root}>
      <div className={classes.caption} onClick={() => setAnchorEl(document.getElementById("AccountsPanelAccountIcon"))}>
        <div className={classes.userName}>{user?.displayName}</div>
        {user?.photoURL ? (
          <img id="AccountsPanelAccountIcon" src={user.photoURL} className={classes.accountImage} />
        ) : (
          <AccountCircleIcon id="AccountsPanelAccountIcon" className={classes.accountIcon} />
        )}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <List dense={true}>
          <ListItem button key="logout" onClick={onLogOut}>
            <ListItemText primary={t("Logout")} />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(AccountsPanel));
