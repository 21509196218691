import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GroupResourceListItem from "../../../components/groups/detail/GroupResourceListItem";
import { RootState } from "../../../modules";
import {
  GitHubRepositoryMetadata,
  LinkMetadata,
  removeLink,
  ResourceType,
  SharedDriveMetadata,
  SourceRepositoryMetadata,
} from "../../../modules/groups";
import { getCurrentUserIsAdmin } from "../../../modules/authentication/selector";

interface ExportProps {
  sharedDrive?: SharedDriveMetadata;
  gitHubRepository?: GitHubRepositoryMetadata;
  sourceRepository?: SourceRepositoryMetadata;
  link?: LinkMetadata;
}

interface StateProps {
  groupId?: string;
  isAdmin: boolean;
}

interface DispatchProps {
  removeLink: (groupId: string, id: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GroupResourceListItemContainer: React.FC<Props> = ({
  sharedDrive,
  gitHubRepository,
  sourceRepository,
  link,
  isAdmin,
  groupId,
  removeLink,
}) => {
  const openUrl = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  if (sharedDrive) {
    return (
      <GroupResourceListItem
        type={ResourceType.Drive}
        name={sharedDrive.name}
        isAdmin={isAdmin}
        onClick={() => openUrl(sharedDrive.url)}
      />
    );
  }
  if (gitHubRepository) {
    return (
      <GroupResourceListItem
        type={ResourceType.GitHubRepository}
        name={gitHubRepository.name}
        isAdmin={isAdmin}
        onClick={() => openUrl(gitHubRepository.url)}
      />
    );
  }
  if (sourceRepository) {
    return (
      <GroupResourceListItem
        type={ResourceType.CloudSourceRepository}
        name={sourceRepository.name}
        isAdmin={isAdmin}
        onClick={() => openUrl(sourceRepository.url)}
      />
    );
  }
  if (link) {
    return (
      <GroupResourceListItem
        type={ResourceType.Link}
        name={link.title}
        isAdmin={isAdmin}
        onClick={() => openUrl(link.url)}
        onRemoveClick={() => groupId && removeLink(groupId, link.id)}
      />
    );
  }
  return null;
};

function mapStateToProps(state: RootState): StateProps {
  return {
    groupId: state.groups.information?.id,
    isAdmin: getCurrentUserIsAdmin(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    removeLink: (groupId, id) => dispatch(removeLink({ groupId, id })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupResourceListItemContainer);
