import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../modules";
import CreateGroupSnackbarContainer from "./groups/list/CreateGroupSnackbarContainer";
import GitHubSynchronizeRequestedSnackbarContainer from "./groups/detail/GitHubSynchronizeRequestedSnackbarContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const SnackbarsContainer: React.FC<Props> = () => {
  return (
    <>
      <CreateGroupSnackbarContainer />
      <GitHubSynchronizeRequestedSnackbarContainer />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarsContainer);
