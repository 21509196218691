import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CreateGroupSnackbar from "../../../components/groups/list/CreateGroupSnackbar";
import { LoadingState, RootState } from "../../../modules";
import { clearCreateGroupError } from "../../../modules/groups";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  loadingState: LoadingState;
}

interface DispatchProps {
  clearCreateGroupError: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const CreateGroupSnackbarContainer: React.FC<Props> = ({ loadingState, clearCreateGroupError }) => {
  return <CreateGroupSnackbar loadingState={loadingState} onClose={clearCreateGroupError} />;
};

function mapStateToProps(state: RootState): StateProps {
  return {
    loadingState: state.groups.createLoadingState,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    clearCreateGroupError: () => dispatch(clearCreateGroupError()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupSnackbarContainer);
