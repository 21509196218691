import { createStyles, Theme } from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function styles(theme: Theme) {
  return createStyles({
    root: {
      background: theme.palette.background.default,
      display: "flex",
      flexGrow: 1,
      height: "calc(100vh - 64px)",
      justifyContent: "center",
      alignItems: "center",
    },
  });
}
