import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import ProjectsListItem from "../../components/dashboard/ProjectsListItem";
import { RootState } from "../../modules";
import { GroupMetadata } from "../../modules/groups";
import { push } from "connected-react-router";

interface ExportProps {
  group: GroupMetadata;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  showGroup: (groupId: string) => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const ProjectsListItemContainer: React.FC<Props> = ({ group, showGroup }) => {
  return <ProjectsListItem group={group} onClick={() => showGroup(group.id)} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    showGroup: (groupId) => dispatch(push(`/groups/${encodeURIComponent(groupId)}`)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListItemContainer);
