import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoginPage from "../../components/session/LoginPage";
import { RootState } from "../../modules";
import { requestSignIn } from "../../modules/authentication";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  requestSignIn: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const LoginPageContainer: React.FC<Props> = ({ requestSignIn }) => {
  return <LoginPage onSignIn={() => requestSignIn()} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    requestSignIn: () => dispatch(requestSignIn()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
