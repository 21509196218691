import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Route, Switch } from "react-router";
import DashboardPageContainer from "./dashboard/DashboardPageContainer";
import NotFoundPageContainer from "./NotFoundPageContainer";
import { RootState } from "../modules";
import GitHubOAuthCallbackPageContainer from "./github/GitHubOAuthCallbackPageContainer";
import GroupPageContainer from "./groups/detail/GroupPageContainer";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DispatchProps {}

type Props = ExportProps & StateProps & DispatchProps;

const Router: React.FC<Props> = () => {
  return (
    <Switch>
      <Route exact={true} path="/githubOAuthCallback" component={GitHubOAuthCallbackPageContainer} />
      <Route exact={true} path="/groups/:groupId" component={GroupPageContainer} />
      <Route exact={true} path="/" component={DashboardPageContainer} />
      <Route component={NotFoundPageContainer} />
    </Switch>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
