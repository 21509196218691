import * as React from "react";
import { IconButton, ListItem, ListItemIcon, ListItemText, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GroupResourceListItemStyles";
import FolderIcon from "@material-ui/icons/Folder";
import GitHubIcon from "@material-ui/icons/GitHub";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LinkIcon from "@material-ui/icons/Link";
import CloseIcon from "@material-ui/icons/Close";
import { ResourceType } from "../../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  type: ResourceType;
  name: string;
  isAdmin: boolean;
  onClick: () => void;
  onRemoveClick?: () => void;
}

const GroupResourceListItem: React.FC<Props> = ({ type, name, isAdmin, onClick, onRemoveClick, t }) => (
  <ListItem button onClick={onClick}>
    <ListItemIcon>
      {type === ResourceType.Drive ? <FolderIcon /> : null}
      {type === ResourceType.GitHubRepository ? <GitHubIcon /> : null}
      {type === ResourceType.CloudSourceRepository ? <AccountTreeIcon /> : null}
      {type === ResourceType.Link ? <LinkIcon /> : null}
    </ListItemIcon>
    <ListItemText>
      {type === ResourceType.Drive ? t("Drive") : null}
      {type === ResourceType.GitHubRepository ? t("GitHub Repository") : null}
      {type === ResourceType.CloudSourceRepository ? t("Cloud Source Repository") : null}
      {type === ResourceType.Link ? t("Link") : null}
      {" : "}
      {name}
    </ListItemText>
    {isAdmin && type === ResourceType.Link && (
      <div>
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemoveClick && onRemoveClick();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    )}
  </ListItem>
);

export default withTranslation()(withStyles(styles)(GroupResourceListItem));
