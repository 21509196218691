import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import GitHubSynchronizeRequestedSnackbar from "../../../components/groups/detail/GitHubSynchronizeRequestedSnackbar";
import { RootState } from "../../../modules";
import { clearGitHubSynchronizeRequested } from "../../../modules/groups";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

interface StateProps {
  open: boolean;
}

interface DispatchProps {
  clearGitHubSynchronizeRequested: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const GitHubSynchronizeRequestedSnackbarContainer: React.FC<Props> = ({ open, clearGitHubSynchronizeRequested }) => {
  return <GitHubSynchronizeRequestedSnackbar open={open} onClose={clearGitHubSynchronizeRequested} />;
};

function mapStateToProps(state: RootState): StateProps {
  return {
    open: state.groups.gitHubSynchronizeRequested,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    clearGitHubSynchronizeRequested: () => dispatch(clearGitHubSynchronizeRequested()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GitHubSynchronizeRequestedSnackbarContainer);
