import { LoadingState } from "../index";
import actionCreatorFactory from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";

// models
// ----------------------------------------

export enum ResourceType {
  Drive = "drive",
  GitHubRepository = "github",
  CloudSourceRepository = "sourceRepository",
  Link = "link",
}

export interface GroupMetadata {
  id: string;
  name: string;
}

export interface SharedDriveMetadata {
  id: string;
  name: string;
  url: string;
}

export interface SourceRepositoryMetadata {
  id: string;
  name: string;
  url: string;
}

export interface GitHubRepositoryMetadata {
  name: string;
  fullName: string;
  url: string;
}

export interface LinkMetadata {
  id: string;
  title: string;
  url: string;
}

export interface GroupInformation {
  id: string;
  name: string;
  groupUrl: string;
  sharedDrives: SharedDriveMetadata[];
  sourceRepositories: SourceRepositoryMetadata[];
  gitHubRepositories: GitHubRepositoryMetadata[];
  links: LinkMetadata[];
}

// Support Functions
// ----------------------------------------

export const accountPartOfEmail = (email: string): string | undefined => {
  const match = email.match(/^[^@]+/);
  return match !== null ? match[0] : undefined;
};

// State
// ----------------------------------------

export interface GroupsState {
  showCreateDialog: boolean;
  createLoadingState: LoadingState;
  information?: GroupInformation;
  informationLoadingState: LoadingState;
  gitHubSynchronizeRequested: boolean;
  showAddResourceDialog: boolean;
  addResourceLoadingState: LoadingState;
}

const initialState: GroupsState = {
  showCreateDialog: false,
  createLoadingState: LoadingState.Initial,
  informationLoadingState: LoadingState.Initial,
  gitHubSynchronizeRequested: false,
  showAddResourceDialog: false,
  addResourceLoadingState: LoadingState.Initial,
};

// Action Parameters
// ----------------------------------------

export interface CreateGroupActionParameters {
  groupId: string;
  groupName: string;
}

export interface GetGroupActionParameters {
  groupId: string;
}

export interface GetGroupActionResultParameters {
  information: GroupInformation;
}

export interface RequestSynchronizeGitHubUsersParameters {
  groupId: string;
}

export interface AddResourceActionParameters {
  groupId: string;
  type: ResourceType;
  suffix: string;
  title: string;
  url: string;
}

export interface RemoveLinkActionParameters {
  groupId: string;
  id: string;
}

// Action Creators
// ----------------------------------------

const actionCreator = actionCreatorFactory("CtimeProjects/Groups");
export const showCreateGroupDialog = actionCreator<void>("ShowCreateGroupDialog");
export const cancelCreateGroupDialog = actionCreator<void>("CancelCreateGroupDialog");
export const createGroup = actionCreator<CreateGroupActionParameters>("CreateGroup");
export const createGroupLoading = actionCreator.async<CreateGroupActionParameters, void, Error>("CreateGroupLoading");
export const clearCreateGroupError = actionCreator<void>("ClearCreateGroupError");
export const getGroup = actionCreator<GetGroupActionParameters>("GetGroup");
export const getGroupLoading = actionCreator.async<GetGroupActionParameters, GetGroupActionResultParameters, Error>(
  "GetGroupLoading"
);
export const requestSynchronizeGitHubUsers = actionCreator<RequestSynchronizeGitHubUsersParameters>(
  "RequestSynchronizeGitHubUsers"
);
export const requestSynchronizeGitHubUsersLoading = actionCreator.async<
  RequestSynchronizeGitHubUsersParameters,
  void,
  Error
>("RequestSynchronizeGitHubUsersLoading");
export const clearGitHubSynchronizeRequested = actionCreator<void>("ClearGitHubSynchronizeRequested");
export const showAddResourceDialog = actionCreator<void>("ShowAddResourceDialog");
export const cancelAddResourceDialog = actionCreator<void>("CancelAddResourceDialog");
export const addResource = actionCreator<AddResourceActionParameters>("AddResource");
export const addResourceLoading = actionCreator.async<AddResourceActionParameters, void, Error>("AddResourceLoading");
export const removeLink = actionCreator<RemoveLinkActionParameters>("RemoveLink");
export const removeLinkLoading = actionCreator.async<RemoveLinkActionParameters, void, Error>("RemoveLinkLoading");

// Reducer
// ----------------------------------------

const reducer = reducerWithInitialState(initialState)
  .case(showCreateGroupDialog, (state) => ({ ...state, showCreateDialog: true }))
  .case(cancelCreateGroupDialog, (state) => ({ ...state, showCreateDialog: false }))
  .case(createGroup, (state) => ({ ...state, showCreateDialog: false }))
  .case(createGroupLoading.started, (state) => ({ ...state, createLoadingState: LoadingState.Loading }))
  .case(createGroupLoading.done, (state) => ({ ...state, createLoadingState: LoadingState.Initial }))
  .case(createGroupLoading.failed, (state) => ({ ...state, createLoadingState: LoadingState.Failed }))
  .case(clearCreateGroupError, (state) => ({ ...state, createLoadingState: LoadingState.Initial }))
  .case(getGroup, (state) => ({ ...state, showCreateDialog: false }))
  .case(getGroupLoading.started, (state) => ({ ...state, informationLoadingState: LoadingState.Loading }))
  .case(getGroupLoading.done, (state, { result }) => ({
    ...state,
    informationLoadingState: LoadingState.Initial,
    information: result.information,
  }))
  .case(getGroupLoading.failed, (state) => ({ ...state, informationLoadingState: LoadingState.Failed }))
  .case(requestSynchronizeGitHubUsersLoading.done, (state) => ({ ...state, gitHubSynchronizeRequested: true }))
  .case(clearGitHubSynchronizeRequested, (state) => ({ ...state, gitHubSynchronizeRequested: false }))
  .case(showAddResourceDialog, (state) => ({ ...state, showAddResourceDialog: true }))
  .case(cancelAddResourceDialog, (state) => ({ ...state, showAddResourceDialog: false }))
  .case(addResource, (state) => ({ ...state, showAddResourceDialog: false }))
  .case(addResourceLoading.started, (state) => ({
    ...state,
    addResourceLoadingState: LoadingState.Loading,
    informationLoadingState: LoadingState.Loading,
  }))
  .case(addResourceLoading.done, (state) => ({ ...state, addResourceLoadingState: LoadingState.Initial }))
  .case(addResourceLoading.failed, (state) => ({ ...state, addResourceLoadingState: LoadingState.Failed }))
  .case(removeLink, (state) => ({ ...state, showAddResourceDialog: false }))
  .case(removeLinkLoading.started, (state) => ({
    ...state,
    addResourceLoadingState: LoadingState.Loading,
    informationLoadingState: LoadingState.Loading,
  }))
  .case(removeLinkLoading.done, (state) => ({ ...state, addResourceLoadingState: LoadingState.Initial }))
  .case(removeLinkLoading.failed, (state) => ({ ...state, addResourceLoadingState: LoadingState.Failed }));
export default reducer;
