import * as React from "react";
import { ListItem, ListItemIcon, ListItemText, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./ProjectsListItemStyles";
import FolderIcon from "@material-ui/icons/Folder";
import { GroupMetadata } from "../../modules/groups";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  group: GroupMetadata;
  onClick: () => void;
}

const ProjectsListItem: React.FC<Props> = ({ group, onClick }) => (
  <ListItem button onClick={onClick}>
    <ListItemIcon>
      <FolderIcon />
    </ListItemIcon>
    <ListItemText>{group.name}</ListItemText>
  </ListItem>
);
export default withTranslation()(withStyles(styles)(ProjectsListItem));
