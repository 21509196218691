import * as React from "react";
import { IconButton, Menu, MenuItem, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GroupBasicInformationPanelStyles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useRef } from "react";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  groupName: string;
  groupId: string;
  groupUrl: string;
  isAdmin: boolean;
  menuOpen: boolean;
  onBack: () => void;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  onSynchronizeGitHub: () => void;
}

const GroupBasicInformationPanel: React.FC<Props> = ({
  groupName,
  groupId,
  groupUrl,
  isAdmin,
  menuOpen,
  onBack,
  onMenuOpen,
  onMenuClose,
  onSynchronizeGitHub,
  classes,
  t,
}) => {
  const menuButtonRef = useRef(null);
  return (
    <div className={classes.root}>
      <Paper className={classes.frame}>
        <div className={classes.titleArea}>
          <IconButton onClick={onBack}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography className={classes.title} component="h2" variant="h6">
            {groupName} (
            <a href={groupUrl} target="_blank" rel="noopener noreferrer">
              {groupId}
            </a>
            )
          </Typography>
          {isAdmin ? (
            <>
              <IconButton ref={menuButtonRef} onClick={onMenuOpen}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
              <Menu open={menuOpen} anchorEl={menuButtonRef.current} onClose={onMenuClose}>
                <MenuItem onClick={onSynchronizeGitHub}>{t("Synchronize GitHub accounts")}</MenuItem>
              </Menu>
            </>
          ) : null}
        </div>
      </Paper>
    </div>
  );
};

export default withTranslation()(withStyles(styles)(GroupBasicInformationPanel));
