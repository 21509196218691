import * as React from "react";
import { CssBaseline, WithStyles, withStyles } from "@material-ui/core";
import styles from "./RootStyles";

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode;
}

const Root: React.FC<Props> = ({ classes, children }) => (
  <div className={classes.root}>
    <CssBaseline />
    {children}
  </div>
);

export default withStyles(styles)(Root);
