import * as React from "react";
import { WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GroupPageStyles";
import PageRoot from "../../parts/PageRoot";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  children?: React.ReactNode;
}

const GroupPage: React.FC<Props> = ({ children, classes }) => (
  <PageRoot>
    <div className={classes.root}>{children}</div>
  </PageRoot>
);

export default withTranslation()(withStyles(styles)(GroupPage));
