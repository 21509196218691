import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import NotPermittedPage from "../../components/session/NotPermittedPage";
import { RootState } from "../../modules";
import { requestSignOut } from "../../modules/authentication";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ExportProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StateProps {}

interface DispatchProps {
  requestSignOut: () => void;
}

type Props = ExportProps & StateProps & DispatchProps;

const NotPermittedPageContainer: React.FC<Props> = ({ requestSignOut }) => {
  return <NotPermittedPage onSignOut={requestSignOut} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function mapStateToProps(state: RootState): StateProps {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    requestSignOut: () => dispatch(requestSignOut()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotPermittedPageContainer);
