import * as React from "react";
import { Button, Paper, Typography, WithStyles, withStyles } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./GitHubPanelStyles";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  gitHubId: string | null;
  onAuthorize: () => void;
}

const GitHubPanel: React.FC<Props> = ({ gitHubId, onAuthorize, classes, t }) => (
  <div className={classes.root}>
    <Paper className={classes.frame}>
      <Typography variant="subtitle1">{t("GitHub Link")}</Typography>
      <div>GitHub ID: {gitHubId || `(${t("Not Linked")})`}</div>
      <div>
        <Button variant="contained" onClick={onAuthorize}>
          {t("Authorize")}
        </Button>
      </div>
    </Paper>
  </div>
);

export default withTranslation()(withStyles(styles)(GitHubPanel));
