import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import styles from "./AddResourceDialogStyles";
import { ResourceType } from "../../../modules/groups";
import GitHubIcon from "@material-ui/icons/GitHub";
// import FolderIcon from "@material-ui/icons/Folder";
// import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LinkIcon from "@material-ui/icons/Link";

interface Props extends WithStyles<typeof styles>, WithTranslation {
  open: boolean;
  groupIdPrefix: string;
  isDuplicate: boolean;
  type: ResourceType;
  name: string;
  title: string;
  url: string;
  onChangeType: (type: ResourceType) => void;
  onChangeName: (name: string) => void;
  onChangeTitle: (title: string) => void;
  onChangeUrl: (url: string) => void;
  onCancel: () => void;
  onAdd: () => void;
}

const AddResourceDialog: React.FC<Props> = ({
  open,
  groupIdPrefix,
  isDuplicate,
  type,
  name,
  title,
  url,
  onChangeType,
  onChangeName,
  onChangeTitle,
  onChangeUrl,
  onCancel,
  onAdd,
  classes,
  t,
}) => (
  <Dialog open={open} fullWidth>
    <DialogTitle>{t("Add Resource")}</DialogTitle>
    <DialogContent>
      <div className={classes.dialogForm}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="AddResource_Type" shrink>
            {t("Resource Type")}
          </InputLabel>
          <Select
            fullWidth
            labelId="AddResource_Type"
            value={type}
            onChange={(e) => onChangeType(e.target.value as ResourceType)}
          >
            <MenuItem value={ResourceType.GitHubRepository}>
              <GitHubIcon fontSize="small" className={classes.resourceListIcon} />
              {t("GitHub Repository")}
            </MenuItem>
            {/*
            <MenuItem value={ResourceType.Drive}>
              <FolderIcon fontSize="small" className={classes.resourceListIcon} />
              {t("Drive")}
            </MenuItem>
            <MenuItem value={ResourceType.CloudSourceRepository}>
              <AccountTreeIcon fontSize="small" className={classes.resourceListIcon} />
              {t("Cloud Source Repository")}
            </MenuItem>
            */}
            <MenuItem value={ResourceType.Link}>
              <LinkIcon fontSize="small" className={classes.resourceListIcon} />
              {t("Link")}
            </MenuItem>
          </Select>
        </FormControl>
        {type === ResourceType.Link ? (
          <>
            <TextField
              label={t("Title")}
              value={title}
              className={classes.formControl}
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => onChangeTitle(e.target.value)}
            />
            <TextField
              label={t("URL")}
              value={url}
              className={classes.formControl}
              fullWidth
              InputLabelProps={{ shrink: true }}
              onChange={(e) => onChangeUrl(e.target.value)}
            />
          </>
        ) : (
          <TextField
            error={isDuplicate}
            helperText={isDuplicate ? t("Already exist this name resource") : ""}
            label={t("Resource Name")}
            value={name}
            className={classes.formControl}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{groupIdPrefix + (name.length > 0 ? "_" : "")}</InputAdornment>
              ),
            }}
            onChange={(e) => onChangeName(e.target.value)}
          />
        )}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>{t("Cancel")}</Button>
      <Button onClick={onAdd} disabled={isDuplicate}>
        {t("Add")}
      </Button>
    </DialogActions>
  </Dialog>
);

export default withTranslation()(withStyles(styles)(AddResourceDialog));
