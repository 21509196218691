import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  })
  .then(() => (window.document.title = i18n.t("PRODUCTION C-time Project Management System")));

export default i18n;
