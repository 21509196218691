import { createStyles, Theme } from "@material-ui/core";

export default function styles(theme: Theme) {
  return createStyles({
    root: {
      margin: theme.spacing(1),
    },
    frame: {
      padding: theme.spacing(2),
    },
    titleArea: {
      display: "flex",
    },
    title: {
      flexGrow: 1,
    },
  });
}
